import { useRouter } from 'next/router'
import Script from 'next/script'
import { useContext, useEffect } from 'react'
import { gtag } from '../lib/gtag'
import { AppContext } from '../contexts/AppContext'

export const GoogleAnalytics = () => {
  const router = useRouter()
  const { brand } = useContext(AppContext)
  const googleTagId = brand?.google_tag_id

  useEffect(() => {
    const handleRouteChange = (url) => {
      const ids = [process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS]

      if (googleTagId) {
        ids.push(googleTagId)
      }

      gtag.pageview(url, ids)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [router.events, googleTagId])

  useEffect(() => {
    gtag.pageview(window.location.pathname, [googleTagId])
  }, [googleTagId])

  return (
    <>
      <Script
        id="load-gtag"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script
        id="google-analytics"
        strategy="afterInteractive"
      >
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `}
      </Script>
    </>
  )
}
