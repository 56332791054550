// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const gtag = {
  pageview: (url, ids = []) => {
    ids.forEach((id) => {
      if (!id) {
        return
      }

      // @ts-ignore
      window.gtag('config', id, { page_path: url })
    })
  },

  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  event: ({ action, category, label, value }) => {
    // @ts-ignore
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    })
  },

  conversion(conversionId, transactionId, value) {
    // @ts-ignore
    window.gtag('event', 'conversion', {
      send_to: conversionId,
      value,
      currency: 'USD',
      transaction_id: transactionId,
    })
  },
}
