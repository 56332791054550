import classNames from 'classnames'
import React from 'react'

interface Props {
  positionClassName?: string
}

export const RequiresActionPulse: React.FC<Props> = ({ positionClassName = 'absolute top-[-5px] left-[-5px]' }) => (
  <span className={classNames('flex h-5 w-5', positionClassName)}>
    <span className="animate-ping absolute top-[-2.5px] left-[-2.5px] inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75" />
    <span className="relative inline-flex rounded-full h-4 w-4 bg-yellow-500" />
  </span>
)
