import React, { useContext } from 'react'
import { SelectInput, Suspense, useFetch } from 'journey-ui'
import { format } from 'date-fns'
import { AppContext } from '../../contexts/AppContext'
import { ScheduleModalContext } from '../../contexts/ScheduleModalContext'

export const ScheduleDateInput = () => {
  const { scheduleModalOpen, brand, order, setOrder } = useContext(AppContext)
  const { scheduledDate, setScheduledDate } = useContext(ScheduleModalContext)
  const onDateChange = (e) => setScheduledDate(e.target.value)

  const [scheduleDates = [], setScheduleDates, scheduleDateFetcher] = useFetch<string[]>({
    url: `/api/brand/${brand?.hash_id}/schedule-dates`,
    isAllowed: scheduleModalOpen,
  })

  const selectOptions = scheduleDates.map((slotTimeStr) => format(new Date(slotTimeStr), 'E MMM do'))

  return (
    <Suspense hook={scheduleDateFetcher} className="items-center">
      <SelectInput
        className="w-3/4 sm:w-1/2 just"
        value={scheduledDate}
        onChange={onDateChange}
        options={selectOptions}
        placeholder="Select the scheduled date"
      />
    </Suspense>
  )
}
