import { Button, Card, enhancedFetch, Icon, Modal, ModalContent, ModalHeader, TextAreaInput, TextInput } from 'journey-ui'
import { CheckCircle, CircleWavyWarning } from 'phosphor-react'
import React, { useContext, useEffect, useState } from 'react'
import usePlacesAutocomplete, { Suggestion } from 'use-places-autocomplete'
import { AppContext } from '../../contexts/AppContext'
import { money } from '../../helpers'
import { GoogleMapsLoaded } from '../GoogleMapsLoaded'

const ChangeDelivery = () => {
  const { order, deliveryLocationModalOpen, brand, setDeliveryLocationModalOpen, setOrder } = useContext(AppContext)
  const [location, setLocation] = useState()

  // useEffect(() => {
  //   if (brand?.longitude) {
  //     // todo: this breaks it for some reason.
  //     // setLocation(new google.maps.LatLng(parseFloat(brand.latitude), parseFloat(brand.longitude)))
  //   }
  // }, [brand?.latitude, brand?.longitude])

  const {
    value: autocompleteQuery,
    setValue: setAutocompleteQuery,
    suggestions,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: {
      location,
      types: ['address'],
    },
  })

  const handleAutoCompleteInput = (e) => {
    // Update the keyword of the input element
    setAutocompleteQuery(e.target.value)
  }

  const onLocationBtnClick = async (location: Suggestion) => {
    const newOrder = await enhancedFetch<Order>('/api/order/change-type', {
      method: 'POST',
      body: {
        type: 'delivery',
        place_id: location.place_id,
      },
    })

    setOrder(newOrder)

    await clearSuggestions()
  }

  const onPickupClick = async () => {
    const newOrder = await enhancedFetch<Order>('/api/order/change-type', {
      method: 'POST',
      body: {
        type: 'pickup',
      },
    })

    setOrder(newOrder)
  }

  const [instructions, setInstructions] = useState('')

  const onChangeInstructions = (e) => {
    setInstructions(e.target.value)
  }

  const onUpdateInstructionClick = async () => {
    const newOrder = await enhancedFetch<Order>('/api/order/update', {
      method: 'PUT',
      body: {
        instructions,
      },
    })

    setOrder(newOrder)
    setDeliveryLocationModalOpen(false)
  }

  const orderInstructions = order?.instructions

  useEffect(() => {
    setInstructions(orderInstructions)
  }, [orderInstructions])

  return (
    <div className="w-full space-y-2">
      {order?.type === 'delivery' ? (
        <div className="flex justify-between items-center w-full text-white bg-green-600 p-2 rounded">
          <div className="flex items-center space-x-2">
            <Icon src={CheckCircle} />
            <span>{`Delivering to "${order?.address}"${order.delivery_quote_hash_id ? ' using DoorDash.' : ''}`}</span>
          </div>
          <Button onClick={onPickupClick}>
            CANCEL
          </Button>
        </div>
      ) : null}
      {order?.delivery_quote_hash_id ? (
        <div className="p-2 flex items-center space-x-2 rounded bg-yellow-600 text-white">
          <Icon className="flex-shrink-0" src={CircleWavyWarning} />
          <span>
            Please remember that the delivery fee of
            {` $${money(order.third_party_delivery_fee)} `}
            is based upon the third party service and we don't have control over what the fee is.
          </span>
        </div>
      ) : null}
      <TextInput label="Delivery Address" autoFocus value={autocompleteQuery} onChange={handleAutoCompleteInput} className="bg-white" />
      {order?.type === 'delivery' ? (
        <div className="space-y-2">
          <TextAreaInput
            label="Delivery Instructions"
            value={instructions}
            onChange={onChangeInstructions}
            placeholder="Apt number, gate code, delivery instructions..."
            className="bg-white"
          />
          <Button className="primary" onClick={onUpdateInstructionClick}>
            Update
          </Button>
        </div>
      ) : null}
      {suggestions.data.map((googleLocation) => (
        <Button key={googleLocation.place_id} className="w-full border-none" onClick={() => onLocationBtnClick(googleLocation)}>
          {googleLocation.description}
        </Button>
      ))}
    </div>
  )
}

const DeliveryLocationModal: React.FC = () => {
  const { order, deliveryLocationModalOpen, brand, setDeliveryLocationModalOpen, setOrder } = useContext(AppContext)

  return (
    <Modal isOpen={deliveryLocationModalOpen} onClose={() => setDeliveryLocationModalOpen(false)} className="min-h-[350px]">
      <ModalHeader>
        Delivery
      </ModalHeader>

      <ModalContent className="space-y-2">
        {deliveryLocationModalOpen ? (
          <GoogleMapsLoaded>
            <ChangeDelivery />
          </GoogleMapsLoaded>
        ) : null}
      </ModalContent>
    </Modal>
  )
}

export default DeliveryLocationModal
