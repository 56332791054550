import { useRouter } from 'next/router'
import { getEchoConfig, getLaravelConfig, Journey, JourneyApp, useFetch, useUser } from 'journey-ui'
import { AppProps } from 'next/app'
import { useCallback, useEffect, useState } from 'react'
import { AppContext, AppContextProps } from '../contexts/AppContext'

import '../styles/app.scss'
import ScheduleModal from '../components/Schedule/ScheduleModal'
import DeliveryLocationModal from '../components/Menu/DeliveryLocationModal'
import { CartModal } from '../components/Menu/CartModal'
import { SpinnerDots } from '../components/SpinnerDots'
import { GoogleAnalytics } from '../components/GoogleAnalytics'

Journey.appendConfig({
  spinnerComponent: SpinnerDots,
  // Any usage of enhancedFetch in getServersideProps needs a domain as we can't always infer the domain like we can on clientside usage.
  serversideBaseDomain: process.env.NEXT_PUBLIC_API_DOMAIN,
  // The URL we will access to ensure upon generating a CSRF cookie. (Usually before our first POST request)
  csrfCookieUrl: '/api/sanctum/csrf-cookie',
  // Laravel support
  ...getLaravelConfig(),
  // Websocket/Pusher support
  ...getEchoConfig({
    broadcaster: 'pusher',
    cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
    forceTLS: true,
    withoutInterceptors: true,
    key: process.env.NEXT_PUBLIC_PUSHER_KEY,
    authEndpoint: '/api/broadcasting/auth',
  }),
})

function App({ Component, pageProps }) {
  const router = useRouter()

  const [order, setOrder, orderFetcher] = useFetch<Order>({
    url: '/api/order',
  })

  const { user, setUser } = useUser<User>('/api/auth/user')

  const refreshCart = useCallback(() => {
    orderFetcher.refresh()
  }, [orderFetcher])

  const [scheduleModalOpen, setScheduleModalOpen] = useState(false)
  const [cartModalOpen, setCartModalOpen] = useState(false)
  const [seenUpsell, setSeenUpsell] = useState(false)
  const [deliveryLocationModalOpen, setDeliveryLocationModalOpen] = useState(false)
  const [cartNotifications, setCartNotifications] = useState<CartNotification[]>([])
  const { brand } = pageProps

  useEffect(() => {
    if (cartNotifications.length <= 0) {
      return
    }

    const t = setTimeout(() => {
      setCartNotifications([])
    }, 3000)

    return () => {
      clearTimeout(t)
    }
  }, [cartNotifications, setCartNotifications])

  const contextValue: AppContextProps = {
    seenUpsell,
    setSeenUpsell,
    cartNotifications,
    setCartNotifications,
    user,
    setUser,
    scheduleModalOpen,
    setScheduleModalOpen,
    cartModalOpen,
    setCartModalOpen,
    deliveryLocationModalOpen,
    setDeliveryLocationModalOpen,
    brand,
    order,
    setOrder,
    refreshCart,
  }

  return (
    <AppContext.Provider value={contextValue}>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS ? <GoogleAnalytics /> : null}

      <JourneyApp routePath={router.asPath}>
        <Component {...pageProps} />
        <ScheduleModal />
        <CartModal />
        <DeliveryLocationModal />
      </JourneyApp>
    </AppContext.Provider>
  )
}

// noinspection JSUnusedGlobalSymbols (PHPStorm doesn't detect NextJS's pages)
export default App
