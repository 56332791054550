import { Button, enhancedFetch, Icon, Modal, ModalContent, ModalFooter, ModalHeader } from 'journey-ui'
import React, { useContext, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Bell, CaretDoubleRight, Clock } from 'phosphor-react'
import { AppContext } from '../../contexts/AppContext'
import { ScheduleModalContext } from '../../contexts/ScheduleModalContext'
import { ScheduleDateInput } from './ScheduleDateInput'
import { ScheduleTimeInput } from './ScheduleTimeInput'

const ScheduleModal: React.FC = () => {
  const { scheduleModalOpen, setScheduleModalOpen, order, brand, setOrder } = useContext(AppContext)
  const [scheduledDate, setScheduledDate] = useState('')
  const [scheduledTime, setScheduledTime] = useState('')

  useEffect(() => {
    if (order?.scheduled_at) {
      /* Schedule dates are set to start of the day. */
      setScheduledDate(format(new Date(order?.scheduled_at), 'E MMM do'))
    } else {
      setScheduledDate(null)
    }

    setScheduledTime(order?.scheduled_at)
  }, [order?.scheduled_at])

  const sendOrderSchedule = async (value) => {
    const newOrder = await enhancedFetch<Order>('/api/order/update', {
      method: 'PUT',
      body: {
        scheduled_at: value,
      },
    })

    setOrder(newOrder)
  }

  return (
    <ScheduleModalContext.Provider value={{ sendOrderSchedule, scheduledDate, setScheduledDate, scheduledTime, setScheduledTime }}>
      <Modal isOpen={scheduleModalOpen} onClose={() => setScheduleModalOpen(false)}>
        <ModalHeader>
          <Icon src={Bell} />
          <span>Schedule Order Later</span>
        </ModalHeader>

        <ModalContent>
          <div className="flex flex-col justify-center items-center w-full space-y-3">
            {brand?.schedule_order_min_minutes > 0 ? (
              <p className="text-center">
                {`Must be ${brand.schedule_order_min_minutes} minutes in advance from opening and ${brand.schedule_order_min_minutes} minutes in advance from now.`}
              </p>
            ) : null}
            <p className="text-xl text-center">I want my order to be placed on:</p>
            <ScheduleDateInput />
            {scheduledDate ? (
              <>
                <p className="text-xl text-center">
                  at
                </p>
                <ScheduleTimeInput />
              </>
            ) : null}
          </div>
        </ModalContent>
        <ModalFooter className="p-0">
          <div className="flex justify-center items-center w-full">
            <Button
              disabled={!scheduledTime}
              className="flex-grow text-lg w-1/2 border-none rounded-none justify-center"
              onClick={async () => {
                await sendOrderSchedule(scheduledTime)
                setScheduleModalOpen(false)
              }}
            >
              <span>Confirm</span>
            </Button>
            {order?.scheduled_at && (
            <Button className="flex-grow w-1/2 border-none rounded-none justify-center danger text-lg" onClick={() => sendOrderSchedule(null)}>
              Cancel
            </Button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    </ScheduleModalContext.Provider>
  )
}

export default ScheduleModal
