import { createContext, Dispatch, SetStateAction } from 'react'

export interface ScheduleModalContextProps {
  scheduledDate: string
  setScheduledDate: Dispatch<SetStateAction<string>>
  scheduledTime: string
  setScheduledTime: Dispatch<SetStateAction<string>>
  sendOrderSchedule: (value: string) => any
}

export const ScheduleModalContext = createContext<ScheduleModalContextProps>({} as ScheduleModalContextProps)
