import React, { useContext } from 'react'
import { Badge, Button, enhancedFetch, Icon } from 'journey-ui'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { Bag, CaretDoubleRight, Circle, CookingPot, Minus, Pencil, TrashSimple } from 'phosphor-react'
import { AppContext } from '../../contexts/AppContext'
import { ChangeOrderType } from './ChangeOrderType'
import { money } from '../../helpers'
import { ChangeScheduleType } from './ChangeScheduleType'
import { RequiresActionPulse } from './RequiresActionPulse'
import { useCheckoutClick } from '../../hooks/useCheckoutClick'

interface Props {

}

function getItemName(item: Item) {
  /* Example of like "Dinner Salad Dinner Salad" which would otherwise display. */
  if (item.size_name === item.name) {
    return item.name
  }
  return [item.size_name, item.name].filter((s) => s !== 'Regular').join(' ')
}

const EmptyCart = () => (
  <div className="p-2 flex flex-col space-y-5 w-full items-center text-center text-xl opacity-50 font-bold py-5">
    <span>You haven't added anything to your cart yet!</span>
    <Icon className="w-24 h-24" src={Bag} />
  </div>
)

export const CartDisplay: React.FC<Props> = () => {
  const { order, setOrder, refreshCart, setCartModalOpen } = useContext(AppContext)
  const router = useRouter()
  const gotoCheckout = useCheckoutClick()

  const onCheckoutClick = () => {
    gotoCheckout()
    setCartModalOpen(false)
  }

  const onItemClick = (item) => {
    router.push(`/item/${item.hash_id}`)
    setCartModalOpen(false)
  }

  const onRemoveItemClick = async (item, e) => {
    e.stopPropagation()

    await enhancedFetch('/api/order/remove-item', {
      body: { item_hash_id: item.hash_id },
      method: 'DELETE',
    })

    refreshCart()
  }

  return (
    <div className="pb-4 w-full flex flex-col">
      <div className="bg-white flex flex-col items-center flex-nowrap p-2 w-full justify-center border-b-2 border-dashed border-gray">
        <ChangeOrderType />
        <ChangeScheduleType />
      </div>

      <div className="flex-grow space-y-2">
        {(!order || order.items.length <= 0) ? <EmptyCart /> : null}

        {order?.items && order.items.map((item) => (
          <div key={item.hash_id}>
            <div className="flex w-full flex-nowrap justify-between items-stretch">
              <div className="flex flex-shrink-0 pl-1 space-x-2 relative items-center w-48 text-left py-2">
                {/* Give it that appearance that it's nested under its parent. */}
                {item.parent_hash_id ? (<Icon className="ml-5" src={Circle} />) : null}
                <span className={classNames('font-bold line-clamp-2', { 'text-lg': !item.parent_hash_id })}>
                  {item.quantity > 1 ? `${item.quantity}x ` : ''}
                  {getItemName(item)}
                </span>
              </div>
              <span className="text-sm flex-shrink-0 self-center">
                {money(item.price)}
              </span>
              <div className="flex items-center flex-shrink-0">
                <Button
                  onClick={(e) => onItemClick(item)}
                  className="h-full overflow-visible bg-gray-100 relative hover:bg-gray-400 rounded-none border-none text-sm px-4"
                >
                  <span>Edit</span>
                  {item.has_issue ? <RequiresActionPulse /> : null}
                </Button>
                <Button
                  onClick={(e) => onRemoveItemClick(item, e)}
                  className="h-full text-red-400 hover:bg-red-400 hover:text-white bg-gray-100 rounded-none border-none text-sm px-2"
                >
                  <Icon src={TrashSimple} />
                </Button>
              </div>
            </div>

            {item.has_free_toppings
              ? (
                <div className={classNames('flex pl-1', { 'ml-5': item.parent_hash_id })}>
                  <Badge className="bg-red-500 animate-pulse text-white font-bold size-sm">Free Toppings Available</Badge>
                </div>
              )
              : null}

            {item.details.map((detail) => (
              <div key={detail} className={classNames('ml-5 flex items-center pl-1 space-x-2')}>
                <Icon src={Circle} />
                <span>{detail}</span>
              </div>
            ))}
          </div>

        ))}
      </div>
      {order?.sub_total ? (
        <div className="flex justify-between border-t-2 border-dashed p-2">
          <span>Subtotal:</span>
          <span>{money(order?.sub_total)}</span>
        </div>
      ) : null}

      {order?.items.length > 0 ? (
        <Button onClick={onCheckoutClick} className="justify-center primary w-11/12 m-auto">
          Checkout
          {' '}
          <Icon src={CaretDoubleRight} className="pb-0.5" />
        </Button>
      ) : null}
    </div>
  )
}
