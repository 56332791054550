import { useRouter } from 'next/router'
import { useContext } from 'react'
import { AppContext } from '../contexts/AppContext'

export function useCheckoutClick() {
  const { brand, seenUpsell, setSeenUpsell } = useContext(AppContext)
  const router = useRouter()

  return () => {
    router.push((!brand.has_upsells || seenUpsell) ? '/checkout' : '/before-checkout')
    setSeenUpsell(true)
  }
}
