import { createContext, Dispatch, SetStateAction } from 'react'

export interface AppContextProps {
  brand: Brand | undefined
  seenUpsell: boolean
  setSeenUpsell: Dispatch<SetStateAction<boolean>>
  user: User
  setUser: Dispatch<SetStateAction<User>>
  scheduleModalOpen: boolean
  setScheduleModalOpen: Dispatch<SetStateAction<boolean>>
  deliveryLocationModalOpen: boolean
  setDeliveryLocationModalOpen: Dispatch<SetStateAction<boolean>>
  cartModalOpen: boolean
  setCartModalOpen: Dispatch<SetStateAction<boolean>>
  order: Order
  setOrder: Dispatch<SetStateAction<Order>>
  setCartNotifications: Dispatch<SetStateAction<CartNotification[]>>
  cartNotifications: CartNotification[]
  refreshCart: () => any
}

export const AppContext = createContext<AppContextProps>({} as AppContextProps)
