import React, { useState } from 'react'
import Script from 'next/script'

/* https://github.com/wellyshen/use-places-autocomplete/discussions/883#discussioncomment-2791354 */
export const GoogleMapsLoaded = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)

  return (
    <>
      <Script
        id="googlemaps"
        type="text/javascript"
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD6YwvT5ivAxezrVZS2St-q1UbSXM5J9vU&libraries=places"
        onReady={() => setLoaded(true)}
        onError={() => setError(true)}
      />
      {error ? (
        <div>
          We cant load the google maps API for some reason on your device. Please ensure you're not running any privacy defenders and refresh to try again.
        </div>
      ) : null}
      {loaded ? children : null}
    </>
  )
}
