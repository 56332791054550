import React, { useContext } from 'react'
import { SelectInput, Suspense, useFetch } from 'journey-ui'
import { format } from 'date-fns'
import { AppContext } from '../../contexts/AppContext'
import { ScheduleModalContext } from '../../contexts/ScheduleModalContext'

export const ScheduleTimeInput = () => {
  const { scheduleModalOpen, brand } = useContext(AppContext)
  const { scheduledTime, setScheduledTime, scheduledDate, sendOrderSchedule } = useContext(ScheduleModalContext)

  const onTimeChange = async (e) => {
    setScheduledTime(e.target.value)
  }

  const [scheduleTimes = [], setScheduleTimes, scheduleTimeFetcher] = useFetch<string[]>({
    url: `/api/brand/${brand?.hash_id}/schedule-times?date=${scheduledDate}`,
    isAllowed: scheduleModalOpen && !!scheduledDate,
  })

  const selectOptions = {}

  scheduleTimes.forEach((slotTimeStr) => {
    selectOptions[slotTimeStr] = format(new Date(slotTimeStr), 'h:mma')
  })

  return (
    <Suspense hook={scheduleTimeFetcher} className="items-center">
      <SelectInput
        className="w-3/4 sm:w-1/2"
        value={scheduledTime}
        onChange={onTimeChange}
        options={selectOptions}
        placeholder="Select the scheduled time"
      />
    </Suspense>
  )
}
