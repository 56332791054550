import { EnhancedFetchError } from 'journey-ui'
import pluralize from 'pluralize'
import * as fs from 'fs'
import * as path from 'path'

export function money(priceInPennies: number, hasFreeLabel = true) {
  const usd = parseFloat(`${priceInPennies / 100}`).toFixed(2)
  return usd === '0.00' && hasFreeLabel ? 'Free' : usd
}

export function sum(amts: number[]) {
  let total = 0

  amts.forEach((amt) => { total += amt })

  return total
}

export function capitalize(str) {
  if (!str) { return '' }
  return str.slice(0, 1).toUpperCase() + str.slice(1, str.length)
}

export const icons: Record<string, string> = {
  mozzarella: '/icons/cheese-1.svg',
  ricotta: '/icons/cheese.svg',
  'fried chicken': '/icons/meat.svg',
  ham: '/icons/meat-1.svg',
  salami: '/icons/salami.svg',
  'italian sausage': '/icons/sausage.svg',
  bacon: '/icons/bacon.svg',
  'pizza sauce': '/icons/jelly.svg',
  mushrooms: '/icons/mushroom.svg',
  'jalapeno peppers': '/icons/chili.svg',
  eggplant: '/icons/aubergine.svg',
  'green olives': '/icons/olives.svg',
  'black olives': '/icons/olives.svg',
  garlic: '/icons/garlic.svg',
  tomatoes: '/icons/tomato.svg',
  onions: '/icons/onion.svg',
  pineapple: '/icons/pineapple.svg',
  broccoli: '/icons/broccoli.svg',
}

// export const icons: Record<string, string> = {
//   mozzarella: '/outline-icons/109-cheese.svg',
//   ricotta: '/outline-icons/110-cheese.svg',
//   'fried chicken': '/outline-icons/138-meat.svg',
//   ham: '/outline-icons/146-meat.svg',
//   salami: '/outline-icons/113-salami.svg',
//   'italian sausage': '/outline-icons/108-sausage.svg',
//   bacon: '/outline-icons/099-bacon.svg',
//   mushrooms: '/outline-icons/163-mushrooms.svg',
//   garlic: '/outline-icons/134-garlic.svg',
//   eggplant: '/outline-icons/159-aubergine.svg',
//   tomatoes: '/outline-icons/161-tomato.svg',
//   pineapple: '/outline-icons/067-pineapple.svg',
// }

export function getRuleToppingNames(rule: Rule, tiles: Tile[]) {
  return tiles
    .filter((t) => rule.tile_hash_ids.includes(t.hash_id))
    .map((t) => t.name)
    .join(', ')
}

export function getRuleDescriptions(rules: Rule[], tiles: Tile[]): string[] {
  return tiles.filter((t) => rules.find((r) => r.tile_hash_ids.includes(t.hash_id)))
    .map((t) => {
      const tileRules = rules.filter((r) => r.tile_hash_ids.includes(t.hash_id))
      const { max } = tileRules.sort((a, b) => a.max - b.max)[0]
      const { min } = tileRules.sort((a, b) => a.min - b.min)[0]
      const { free } = tileRules.sort((a, b) => a.free - b.free)[0]

      if (max === min && max === free) {
        return `${max} free ${pluralize(t.name.toLowerCase(), max)} included.`
      }

      if (max === min && free === 0) {
        return `Please select ${max} ${pluralize(t.name.toLowerCase(), max)}.`
      }

      if (min > 0 && min < max && free === 0) {
        return `Please select at least ${min} ${pluralize(t.name.toLowerCase(), min)}, or up to ${max}.`
      }

      const parts: string[] = []

      if (max > 0) {
        parts.push(`${max} max`)
      }

      if (min > 0) {
        parts.push(`${min} min`)
      }

      if (free > 0) {
        parts.push(`${free} free`)
      }

      return `${t.name}: ${parts.join(' ')}`
    })
}

export type RGB = {
  r: number
  g: number
  b: number
}

export function brandColor2RGB(color: string): RGB {
  const parts = color.split(',').map((p) => p.trim())

  return {
    r: parseInt(parts[0], 10),
    g: parseInt(parts[1], 10),
    b: parseInt(parts[2], 10),
  }
}

export function RGB2BrandColor(rgb: RGB): string {
  return `${rgb.r}, ${rgb.g}, ${rgb.b}`
}

export function amplifyColor(color: string, multiplier: number) {
  const rgb = brandColor2RGB(color)

  rgb.r = Math.min(255, rgb.r > 0 ? rgb.r * multiplier : rgb.r)
  rgb.g = Math.min(255, rgb.g > 0 ? rgb.g * multiplier : rgb.g)
  rgb.b = Math.min(255, rgb.b > 0 ? rgb.b * multiplier : rgb.b)

  return RGB2BrandColor(rgb)
}

export function awsImageUrl(imgSrc) {
  return imgSrc?.replace('https://pizzamico8-dev.s3.amazonaws.com', '/aws')
}

export function createCartNotifications(previousCartNotifications: CartNotification[], previousOrder: Order | undefined, newOrder: Order): CartNotification[] {
  const newItems = newOrder.items.filter((i) => !previousOrder?.items.some((pi) => pi.hash_id === i.hash_id))
  const newNotifications: CartNotification[] = []

  newItems.forEach((newItem) => {
    newNotifications.push({
      item_hash_id: newItem.hash_id,
      message: `Added ${newItem.name}`,
    })
  })

  const previousNotifications = previousCartNotifications.length >= 5 ? previousCartNotifications.slice(newNotifications.length) : previousCartNotifications

  return [...previousNotifications, ...newNotifications]
}

export function isIncludedTopping(sectionTopping: SectionTopping | null, itemPageJson: ItemPageJson) {
  /* Don't compare sizes, there is no toppings dependent on size, if a recipe has a topping, it has it on ALL sizes. */
  return itemPageJson.recipe_toppings.some((t) => t.name === sectionTopping?.name && t.portion_name === sectionTopping?.portion_name)
}

export function calculateItemToppingPrice(sectionTopping: SectionTopping | null, itemPageJson: ItemPageJson) {
  if (isIncludedTopping(sectionTopping, itemPageJson)) {
    return 0
  }

  return sectionTopping.price
}

export function writeLog(log) {
  fs.appendFileSync(path.resolve('log.txt'), `${log}\n`)
}

export function redirectIfNoBrand(e) {
  /* 460 is no brand found. */
  if (e instanceof EnhancedFetchError && e.response.status === 460) {
    return {
      redirect: {
        permanent: false,
        destination: '/',
      },
    }
  }
}
