import { forwardRef } from 'react'

// eslint-disable-next-line react/display-name
export const SpinnerDots = forwardRef<any, HTMLDivElement>((props, ref) => (
  <div className="spinner-dots-wrapper">
    <div className="spinner-dots" ref={ref}>
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  </div>
))
