import classNames from 'classnames'
import { enhancedFetch, Icon, TabButton, TabButtons, Tabs } from 'journey-ui'
import { Car, HandWaving, PersonSimpleWalk } from 'phosphor-react'
import React, { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'

interface Props {
  className?: string
}

export const ChangeOrderType: React.FC<Props> = ({ className }) => {
  const { order, setOrder, brand, setDeliveryLocationModalOpen } = useContext(AppContext)

  const onPickupClick = async () => {
    const newOrder = await enhancedFetch<Order>('/api/order/change-type', {
      method: 'POST',
      body: {
        type: 'pickup',
      },
    })

    setOrder(newOrder)
  }

  const onCurbsideClick = async () => {
    const newOrder = await enhancedFetch<Order>('/api/order/change-type', {
      method: 'POST',
      body: {
        type: 'curbside',
      },
    })

    setOrder(newOrder)
  }

  const onDeliveryClick = () => {
    setDeliveryLocationModalOpen(true)
  }

  const tabWidth = (brand.show_delivery_option && brand.has_curbside) ? 'w-1/3' : 'w-1/2'

  return (
    <Tabs initialTab="pickup" className={classNames('checkout-tabs', className)} value={order?.type}>
      <TabButtons className="segment">
        <TabButton name="pickup" onClick={onPickupClick} className={tabWidth}>
          Pickup
          <Icon src={PersonSimpleWalk} className="pb-1 pl-1" />
        </TabButton>
        {brand.show_delivery_option ? (
          <TabButton name="delivery" onClick={onDeliveryClick} className={tabWidth}>
            Delivery
            <Icon src={Car} className="pb-1 pl-1" />
          </TabButton>
        ) : null}
        {brand.has_curbside ? (
          <TabButton name="curbside" onClick={onCurbsideClick} className={tabWidth}>
            Curbside
            <Icon src={HandWaving} className="pb-1 pl-1" />
          </TabButton>
        ) : null}
      </TabButtons>
    </Tabs>
  )
}
