import { enhancedFetch, Icon, TabButton, TabButtons, Tabs } from 'journey-ui'
import { ArrowBendRightDown, Bell, CaretDown, Clock, Timer } from 'phosphor-react'
import React, { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'

interface Props {
  className?: string
}

export const ChangeScheduleType: React.FC<Props> = () => {
  const { order, setOrder, brand, setScheduleModalOpen } = useContext(AppContext)

  const removeSchedule = async () => {
    if (!order.scheduled_at) {
      return
    }

    const newOrder = await enhancedFetch<Order>('/api/order/update', {
      method: 'PUT',
      body: {
        scheduled_at: null,
      },
    })

    setOrder(newOrder)
  }

  if (!brand.has_scheduled_orders) {
    return <></>
  }

  return (
    <Tabs initialTab="right-now" className="checkout-tabs" value={order?.scheduled_at ? 'schedule-later' : 'right-now'}>
      <TabButtons className="segment">
        <TabButton name="right-now" onClick={() => removeSchedule()} className="w-1/2">
          Right Now
          <Icon src={ArrowBendRightDown} className="pb-1 pl-1" />
        </TabButton>
        <TabButton name="schedule-later" onClick={() => setScheduleModalOpen(true)} className="w-1/2">
          Scheduled Later
          <Icon src={Bell} className="pb-1 pl-1" />
        </TabButton>
      </TabButtons>
    </Tabs>
  )
}
