import { Icon, Modal, ModalContent, ModalHeader } from 'journey-ui'
import React, { useContext } from 'react'
import { ShoppingCart } from 'phosphor-react'
import { CartDisplay } from './CartDisplay'
import { AppContext } from '../../contexts/AppContext'

export const CartModal = () => {
  const { cartModalOpen, setCartModalOpen } = useContext(AppContext)

  return (
    <Modal className="sm:w-96 sm:h-full" wrapClassName="sm:justify-end" animateFrom="right" isOpen={cartModalOpen} onClose={() => setCartModalOpen(false)}>
      <ModalHeader className="text-primary-contrast bg-primary">
        Cart
        {' '}
        <Icon src={ShoppingCart} className="pb-1.5 pt-0.5" />
      </ModalHeader>
      <ModalContent className="p-0">
        <CartDisplay />
      </ModalContent>
    </Modal>
  )
}
